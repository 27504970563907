<template>
  <Cascader
    :data="regionArr"
    :disabled="cascaderDisabled"
    v-model="cascaderArr"
    @on-change="onChangeCascader"
  ></Cascader>
</template>
<script>
export default {
  name: 'Region',
  data () {
    return {
      regionArr: [],
      queryParams: {
        cusCode: '',
        org: ''
      },
      cascaderArr: [],
      cascaderDisabled: false,
      roleLength: 2
    }
  },
  mounted () {
    setTimeout(() => {
      const CIQ_ARRY = this.$localStorage.get('SYS_DICTIONARY').CIQLABEL
      const ORG_ARRY = this.$localStorage.get('SYS_DICTIONARY').ORGANIZATIONLABEL
      const regionData = []
      if (this.$localStorage.get('REGION_DATA') === undefined) {
        CIQ_ARRY.forEach((item, index) => {
          const rsOrgArry = ORG_ARRY.filter(orgItem => {
            return orgItem.value.includes(item.value)
          })
          const ciqItem = { ...item }
          ciqItem.children = [...rsOrgArry]
          regionData.push(ciqItem)
        })
      }
      this.regionArr = regionData
    }, 500)
  },
  methods: {
    init () {
      this.cascaderArr = []
    },
    /**
     * 选择互市
     */
    onChangeCascader (val) {
      const length = val.length
      if (this.roleLength === 4 && length === 0) {
        this.cascaderArr = [this.regionArr[0].value]
        this.queryParams.cusCode = this.cascaderArr[0]
        this.queryParams.org = ''
        this.$emit('onChange', this.queryParams)
        return
      }
      switch (length) {
        case 0:
          this.queryParams.cusCode = ''
          this.queryParams.org = ''
          break

        case 1:
          this.queryParams.cusCode = val[0]
          this.queryParams.org = ''
          break

        case 2:
          this.queryParams.cusCode = val[0]
          this.queryParams.org = val[1]
          break
      }
      this.$emit('onChange', this.queryParams)
    }
  }
}
</script>
