import { SYS_TYPES } from '../types';
import localStorage from 'store';
// import { sysDictionary } from '@/api/sys';
import store from '../../store'
/**
 * 系统状态
 */

const state = {
  // 是否刷新  默认为true 权限加载完后 为false
  isRefresh: true,
  /**
   * 登录状态
   */
  loginStatus: false,
  /**
   * 根据权限生成的路由
   */
  dynamicData: [],
  /**
   * 菜单是否收缩
   */
  isCollapsed: false,
  /**
   * 动态路由
   */
  DYNAMIC_ROUTE: []
}

const getters = {
  getDynamicRoute: state => {
    return state.DYNAMIC_ROUTE;
  },
  getLoginStatus: state => () => {
    if (sessionStorage.getItem('loginStatus') !== null) {
      if (sessionStorage.getItem('loginStatus') === 'false') {
        return false;
      } else {
        return true;
      }
    } else {
      return state.loginStatus;
    }
  },
  getRefresh: state => {
    return state.isRefresh;
  },
  getIsCollapsed: state => {
    // console.log(localStorage.get('isCollapsed'), state.isRefresh)
    // if (!state.isRefresh) {
    //   return localStorage.get('isCollapsed') || false
    // }
    // return localStorage.get('isCollapsed')
    return state.isCollapsed;
  }
};

const mutations = {
  /**
   * 权限处理
   */
  [SYS_TYPES.HANDLER_PERMISSION]: (state, data) => {
    generateMenu(state, data);
    state.isRefresh = false;
  },
  /**
   * 登录状态设置
   */
  [SYS_TYPES.SET_LOGIN_STATUS]: (state, status) => {
    // 查询系统字典
    // sysDictionary().then(({ data }) => {
    //   const rs = {};
    //   Object.keys(data).forEach((pItem, index) => {
    //     const val = [];
    //     Object.keys(data[pItem]).forEach((cItem, index) => {
    //       val.push({ value: cItem, label: data[pItem][cItem] });
    //     });
    //     rs[pItem] = val;
    //   });
    //   localStorage.set('SYS_DICTIONARY', rs);
    // });
    state.loginStatus = status;
    state.isRefresh = false;
    sessionStorage.setItem('loginStatus', status);
    // localStorage.set('loginStatus', status)
  },
  [SYS_TYPES.LOGIN_OUT]: state => {
    // step1 清除登录状态
    state.loginStatus = false;
    state.isRefresh = true;
    localStorage.clearAll();
    sessionStorage.clear();
    store.state.tagNavList = []
    // Cookie.set('loginStatus', false)
    // Cookie.remove('permission')
    // Cookie.remove('menu_opennames')
    // Cookie.remove('active_name')
  },
  [SYS_TYPES.SET_COLLAPSED]: (state, status) => {
    state.isCollapsed = status;
  }
};
const actions = {
  /**
   * 登录完成后权限处理
   * @param data 后台返回的权限菜单
   */
  handlerPermission: ({ commit, state }, data) => {
    return new Promise((resolve, reject) => {
      commit(SYS_TYPES.HANDLER_PERMISSION, data);

      // 去重复
      var hash = {};
      const arr = state.dynamicData.reduce(function (item, next) {
        // eslint-disable-next-line no-unused-expressions
        hash[next.path] ? '' : (hash[next.path] = true && item.push(next));
        return item;
      }, []);

      // 组装路由数据
      const rs = [
        {
          path: '/',
          name: 'Layout',
          component: () => import('@/components/layout/Index.vue'),
          redirect: '/vehicle',
          children: arr
        },
        {
          path: '/*',
          name: '404',
          component: () => import('@/views/404.vue'),
          meta: {
            title: '404'
          }
        }
      ];
      resolve(rs);
    });
  },
  handlerSetLoginStatus: ({ commit, state }, status) => {
    return new Promise((resolve, reject) => {
      commit(SYS_TYPES.SET_LOGIN_STATUS, status);
      resolve({ status: true });
    });
  },
  handlerLoginOut: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      commit(SYS_TYPES.LOGIN_OUT);
      resolve();
    });
  },
  setCollapsedStatus: ({ commit, state }, status) => {
    commit(SYS_TYPES.SET_COLLAPSED, status);
  }
};

/**
 *
 * @param {*} state 上下文
 * @param {*} data 后台菜单
 */
function generateMenu (state, data) {
  data.forEach(item => {
    if (item.childFunc) {
      generateMenu(state, item.childFunc);
    }
    if (item.url) {
      const rs = state.DYNAMIC_ROUTE.filter((rItem, index, arr) => {
        return rItem.path.includes(item.url);
      });
      if (rs.length > 0) {
        state.dynamicData.push(...rs);
      }
    }
  });
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
