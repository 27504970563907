<template>
  <div class="show-header">
    <a @click="back">
      <Icon type="md-arrow-back" size="22" color="#1e9fff" />
    </a>
    <h3 class="title">{{ title }}</h3>
  </div>
</template>
<script>
export default {
  name: 'BackHeader',
  props: {
    title: {
      type: String,
      default: '标题'
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.show-header {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;

  .title {
    margin-left: 24px;
  }
}
</style>
