import Vue from 'vue'
import 'view-design/dist/styles/iview.css'
import {
  Form,
  FormItem,
  Input,
  Icon,
  Button,
  Table,
  Menu,
  MenuGroup,
  MenuItem,
  Row,
  Col,
  Layout,
  Header,
  Content,
  Footer,
  Sider,
  Message,
  Notice,
  Submenu,
  Card,
  LoadingBar,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Drawer,
  DatePicker,
  Select,
  Option,
  Page,
  Spin,
  Divider,
  AutoComplete,
  RadioGroup,
  Radio,
  Modal,
  InputNumber,
  Badge,
  Cascader,
  Switch,
  Steps,
  Step,
  Alert,
  Tag,
  Tabs,
  TabPane,
  Scroll,
  Tree,
  Avatar
} from 'view-design'

Vue.component('Form', Form)
Vue.component('FormItem', FormItem)
Vue.component('Input', Input)
Vue.component('Icon', Icon)
Vue.component('Button', Button)
Vue.component('Table', Table)
Vue.component('Menu', Menu)
Vue.component('MenuGroup', MenuGroup)
Vue.component('MenuItem', MenuItem)
Vue.component('Row', Row)
Vue.component('Col', Col)
Vue.component('Layout', Layout)
Vue.component('Header', Header)
Vue.component('Content', Content)
Vue.component('Footer', Footer)
Vue.component('Sider', Sider)
Vue.component('Submenu', Submenu)
Vue.component('Card', Card)
Vue.component('Dropdown', Dropdown)
Vue.component('DropdownMenu', DropdownMenu)
Vue.component('DropdownItem', DropdownItem)
Vue.component('Drawer', Drawer)
Vue.component('DatePicker', DatePicker)
Vue.component('Select', Select)
Vue.component('Page', Page)
Vue.component('Spin', Spin)
Vue.component('Divider', Divider)
Vue.component('AutoComplete', AutoComplete)
Vue.component('RadioGroup', RadioGroup)
Vue.component('Radio', Radio)
Vue.component('Modal', Modal)
Vue.component('Option', Option)
Vue.component('InputNumber', InputNumber)
Vue.component('Badge', Badge)
Vue.component('Cascader', Cascader)
Vue.component('i-switch', Switch)
Vue.component('Steps', Steps)
Vue.component('Step', Step)
Vue.component('Alert', Alert)
Vue.component('Tag', Tag)
Vue.component('Tabs', Tabs)
Vue.component('TabPane', TabPane)
Vue.component('Scroll', Scroll)
Vue.component('Tree', Tree)
Vue.component('Avatar', Avatar)
Message.config({
  // top: 150,
  duration: 3
})

Vue.prototype.$Message = Message
Vue.prototype.$Notice = Notice
Vue.prototype.$Loading = LoadingBar
Vue.prototype.$Modal = Modal
