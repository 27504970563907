import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugin/iview-ui.js'
import animate from 'animate.css'
import axios from 'axios'
import localStorage from 'store'
import BackHeader from '@/components/BackHeader'
import OrderRangeDatePicker from '@/components/OrderRangeDatePicker'
import RangeDatePicker from '@/components/RangeDatePicker'
import RangeDatePicker2 from '@/components/RangeDatePicker2'
import RangeDatePicker3 from '@/components/RangeDatePicker3'
import RegionCascader from '@/components/RegionCascader.vue';
import Region from '@/components/Region.vue';
import Region2 from '@/components/Region2.vue';
Vue.config.productionTip = false

Vue.component('BackHeader', BackHeader)
Vue.component('OrderRangeDatePicker', OrderRangeDatePicker)
Vue.component('RangeDatePicker', RangeDatePicker)
Vue.component('RangeDatePicker2', RangeDatePicker2)
Vue.component('RangeDatePicker3', RangeDatePicker3)
Vue.component('RegionCascader', RegionCascader)
Vue.component('Region', Region)
Vue.component('Region2', Region2)
Vue.use(animate)

Vue.prototype.$size = window.innerWidth <= 1440 ? 'small' : 'small'
Vue.prototype.$axios = axios
Vue.prototype.$localStorage = localStorage
Vue.prototype.$time = 600

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
