<template>
  <DatePicker
    @on-change="onChangeDate"
    :value="value"
    type="daterange"
    split-panels
    :options="options"
    :editable="false"
    placeholder="请选择清算时间"
    style="width:100%"
  ></DatePicker>
</template>
<script>
// import { formatDate } from '@/utils/common.js';
export default {
  data () {
    return {
      value: [
        // formatDate(new Date(), 'yyyy-MM-dd'),
        // formatDate(new Date(), 'yyyy-MM-dd')
      ],
      options: {
        disabledDate (date) {
          return date && date.valueOf() > Date.now();
        }
      }
    };
  },
  created () {
    // this.$emit('onInitChange', { from: this.value[0], to: this.value[1] });
  },
  methods: {
    onChangeDate (val) {
      this.$emit('onChange', { from: val[0], to: val[1] });
    },
    /**
     * 重置
     */
    reset () {
      this.value = [];
    }
  }
};
</script>
