import localStorage from 'store';

/**
 * 防抖
 * @param {*} fn
 * @param {*} t
 */
export const Debounce = (fn, t) => {
  const delay = t || 500;
  let timer;
  return function () {
    const args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};

/*
 * 函数节流
 */
export const Throttle = (fn, t) => {
  let last;
  let timer;
  const interval = t || 500;
  return function () {
    const args = arguments;
    const now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, interval);
    } else {
      last = now;
      fn.apply(this, args);
    }
  };
};

export const getRouteTitleHandled = (route) => {
  const router = { ...route }
  const meta = { ...route.meta }
  let title = ''
  if (meta.title) {
    if (typeof meta.title === 'function') {
      title = meta.title(router)
    } else title = meta.title
  }
  meta.title = title
  router.meta = meta
  return router
}

export function formatDate (date, fmt) {
  date = new Date(date);
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    S: date.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
    }
  }
  return fmt;
}

export function getDate () {
  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth();
  month = month + 1 > 12 ? 1 : month + 1;
  month = month > 9 ? month : '0' + month.toString();
  var day = d.getDate();
  day = day > 9 ? day : '0' + day.toString();
  var hour = d.getHours();
  hour = hour > 9 ? hour : '0' + hour.toString();
  var minute = d.getMinutes();
  minute = minute > 9 ? minute : '0' + minute.toString();
  var second = d.getSeconds();
  second = second > 9 ? second : '0' + second.toString();
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

/**
 *根据name 获取字典对应的文字
 * @param {*} type 字典类型
 * @param {*} name 字典类型name
 */
export const getDictionaryName = (type, name) => {
  const dictionaryData = localStorage.get('dictionary') || {};
  const dictionaryList = dictionaryData[type] || [];
  const rs = dictionaryList.filter(item => {
    return item.name === name;
  });
  return rs[0] || { desc: '---' };
};

/**
 * 根据字典类型 获取字典集合
 * @param {*} type
 */
export const getDictionary = type => {
  const dictionaryData = localStorage.get('dictionary') || {};
  const dictionaryList = dictionaryData[type] || [];
  return dictionaryList;
};

/**
 * 获取系统字典
 * @param {*} type
 * @param {*} value
 */
export const getSysDictionaryName = (type, value) => {
  const dictionaryData = localStorage.get('SYS_DICTIONARY') || {};
  const dictionaryList = dictionaryData[type] || [];
  const rs = dictionaryList.filter(item => {
    return item.value === value;
  });
  return rs[0] || { text: '---', desc: '---' };
};

/**
 * 根据字典类型 获取字典集合
 * @param {*} type
 */
export const getSysDictionary = type => {
  const dictionaryData = localStorage.get('SYS_DICTIONARY') || {};
  const dictionaryList = dictionaryData[type] || [];
  return dictionaryList;
};

/**
 * 获取进出口字典
 * @param {*} type
 */
export const getieflgSysDictionary = type => {
  if (type === 'I_E_FLAG') {
    var dictionaryList = [{ text: '全部', value: 'ALL' }, { text: '进口', value: 'I' }, { text: '出口', value: 'E' }];
  }
  return dictionaryList;
};

/**
 * 获取可结算方式字典
 * @param {*} type
 */
export const geteftListSysDictionary = type => {
  if (type === 'SETTLE_TYPE') {
    var dictionaryList = [{ text: '全部', value: '' }, { text: '电子', value: 'E' }, { text: '现金', value: 'C' }];
  }
  return dictionaryList;
};