import Vue from 'vue'
import VueRouter from 'vue-router'
import { LoadingBar } from 'view-design'
// import localStorage from 'store'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { path: '/login' } },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/logins',
    name: 'logins',
    component: () => import('@/views/md5Login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/hekouLogin',
    name: 'hekouLogin',
    component: () => import('@/views/shopLogin.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/components/layout/Index.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/Index.vue'),
        meta: {
          isUseCache: false,
          title: '首页',
          keepAlive: true
        }
      },
      {
        path: '/password',
        name: 'password',
        component: () => import('@/views/admin/updatePassword.vue'),
        meta: {
          isUseCache: false,
          title: '修改密码',
          keepAlive: false
        }
      },
      {
        path: '/order/list',
        name: 'order',
        component: () => import('@/views/order/List.vue'),
        meta: {
          isUseCache: false,
          title: '订单管理',
          keepAlive: true
        }
      },
      {
        path: '/order/search',
        name: 'orderSearch',
        component: () => import('@/views/order/Search.vue'),
        meta: {
          isUseCache: false,
          title: '订单查询',
          keepAlive: true
        }
      },
      {
        path: '/order/refund',
        name: 'orderRefund',
        component: () => import('@/views/order/Refund.vue'),
        meta: {
          isUseCache: false,
          title: '订单退款',
          keepAlive: true
        }
      },
      {
        path: '/order/del',
        name: 'orderDel',
        component: () => import('@/views/order/Delete.vue'),
        meta: {
          isUseCache: false,
          title: '订单删除',
          keepAlive: true
        }
      },
      {
        path: '/order/add',
        name: 'orderAdd',
        component: () => import('@/views/order/Add.vue'),
        meta: {
          isUseCache: false,
          title: '订单添加',
          keepAlive: false
        }
      },
      {
        path: '/order/edit',
        name: 'orderEdit',
        component: () => import('@/views/order/Edit.vue'),
        meta: {
          isUseCache: false,
          title: '订单编辑',
          keepAlive: false
        }
      },
      {
        path: '/order/body',
        name: 'orderBody',
        component: () => import('@/views/order/Body.vue'),
        meta: {
          isUseCache: false,
          title: '订单详情',
          keepAlive: false
        }
      },
      {
        path: '/vendee/list',
        name: 'vendee',
        component: () => import('@/views/vendee/List.vue'),
        meta: {
          isUseCache: false,
          title: '边民管理',
          keepAlive: true
        }
      },
      {
        path: '/vendee/add',
        name: 'vendeeAdd',
        component: () => import('@/views/vendee/Add.vue'),
        meta: {
          isUseCache: false,
          title: '边民添加',
          keepAlive: false
        }
      },
      {
        path: '/vendee/edit',
        name: 'vendeeEdit',
        component: () => import('@/views/vendee/Edit.vue'),
        meta: {
          isUseCache: false,
          title: '边民编辑',
          keepAlive: false
        }
      },
      {
        path: '/vendee/body',
        name: 'vendeeBody',
        component: () => import('@/views/vendee/Body.vue'),
        meta: {
          isUseCache: false,
          title: '边民表体管理',
          keepAlive: false
        }
      },
      {
        path: '/vendor/list',
        name: 'vendor',
        component: () => import('@/views/vendor/List.vue'),
        meta: {
          isUseCache: false,
          title: '商铺管理',
          keepAlive: true
        }
      },
      {
        path: '/vendor/add',
        name: 'vendorAdd',
        component: () => import('@/views/vendor/Add.vue'),
        meta: {
          isUseCache: false,
          title: '商铺添加',
          keepAlive: false
        }
      },
      {
        path: '/vendor/edit',
        name: 'vendorEdit',
        component: () => import('@/views/vendor/Edit.vue'),
        meta: {
          isUseCache: false,
          title: '商铺编辑',
          keepAlive: false
        }
      },
      {
        path: '/clearingBank/list',
        name: 'clearingBank',
        component: () => import('@/views/clearingBank/List.vue'),
        meta: {
          isUseCache: false,
          title: '银行管理',
          keepAlive: true
        }
      },
      {
        path: '/bankReceiptReissue/list',
        name: 'bankReceiptReissue',
        component: () => import('@/views/bankReceiptReissue/List.vue'),
        meta: {
          isUseCache: false,
          title: '银行回执补发',
          keepAlive: true
        }
      },
      {
        path: '/organization/list',
        name: 'organization',
        component: () => import('@/views/organization/List.vue'),
        meta: {
          isUseCache: false,
          title: '撮合费开关管理',
          keepAlive: true
        }
      },
      {
        path: '/sale/list',
        name: 'sale',
        component: () => import('@/views/sale/List.vue'),
        meta: {
          isUseCache: false,
          title: '交易结算方式设置',
          keepAlive: true
        }
      },
      {
        path: '/charging/list',
        name: 'operationFrrs',
        component: () => import('@/views/operationFrrs/List.vue'),
        meta: {
          isUseCache: false,
          title: '场所运营费用',
          keepAlive: true
        }
      },
      {
        path: '/chargeOrder/trading',
        name: 'chargeOrderTrading',
        component: () => import('@/views/chargeOrder/Trading.vue'),
        meta: {
          isUseCache: false,
          title: '贸易服务费',
          keepAlive: true
        }
      },
      {
        path: '/chargeOrder/site',
        name: 'chargeOrderSite',
        component: () => import('@/views/chargeOrder/Site.vue'),
        meta: {
          isUseCache: false,
          title: '撮合费',
          keepAlive: true
        }
      },
      {
        path: '/statement',
        name: 'statement',
        component: () => import('@/views/statement/List.vue'),
        meta: {
          isUseCache: false,
          title: '对账单',
          keepAlive: true
        }
      },
      {
        path: '/statement/show',
        name: 'statementShow',
        component: () => import('@/views/statement/Show.vue'),
        meta: {
          isUseCache: false,
          title: '对账单详情',
          keepAlive: true
        }
      },
      {
        path: '/logistics',
        name: 'logistics',
        component: () => import('@/views/logistics/List.vue'),
        meta: {
          isUseCache: false,
          title: '物流查询',
          keepAlive: true
        }
      },
      {
        path: '/statistics/statement',
        name: 'statisticsStatement',
        component: () => import('@/views/statistics/Statement.vue'),
        meta: {
          isUseCache: false,
          title: '对账单统计',
          keepAlive: true
        }
      },
      {
        path: '/statistics/trade',
        name: 'statisticsTrade',
        component: () => import('@/views/statistics/Trade.vue'),
        meta: {
          isUseCache: false,
          title: '交易统计',
          keepAlive: true
        }
      },
      {
        path: '/shop',
        name: 'shop',
        component: () => import('@/views/shop/List.vue'),
        meta: {
          isUseCache: false,
          title: '商品管理',
          keepAlive: true
        }
      },
      {
        path: '/shop/show',
        name: 'shopShow',
        component: () => import('@/views/shop/Show.vue'),
        meta: {
          isUseCache: false,
          title: '商品详情',
          keepAlive: true
        }
      },
      {
        path: '/sysLog',
        name: 'sysLog',
        component: () => import('@/views/sysLog/List.vue'),
        meta: {
          isUseCache: false,
          title: '系统日志',
          keepAlive: true
        }
      },
      {
        path: '/dispatch',
        name: 'dispatch',
        component: () => import('@/views/dispatch/List.vue'),
        meta: {
          isUseCache: false,
          title: '调度管理',
          keepAlive: true
        }
      },
      {
        path: '/dict',
        name: 'dict',
        component: () => import('@/views/dictionary/List.vue'),
        meta: {
          title: '字典管理',
          keepAlive: true
        }
      },
      {
        path: '/dictionary/add',
        name: 'dict-add',
        component: () => import('@/views/dictionary/Add.vue'),
        meta: {
          title: '创建字典'
        }
      }
    ]
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401.vue'),
    meta: {
      title: '401'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '404'
    }
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/500.vue'),
    meta: {
      title: '500'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to.path)
  LoadingBar.start()
  // 登陆判断
  if (sessionStorage.getItem('TOKEN')) {
    if (to.path !== '/login' || to.path !== '/logins' || to.path !== '/hekouLogin') {
      store.dispatch('addTag', to)
    }
    next()
  } else {
    if (to.path === '/login' || to.path === '/logins' || to.path === '/hekouLogin' || to.path === '/401') {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.path }
      })
    }
  }
})

router.afterEach(() => {
  LoadingBar.finish()
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
