<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created () {
    this.$Message.config({
      top: 320,
      duration: 3
    });
  }
}
</script>
<style lang="scss">
@import url("./assets/iconfont/iconfont.css");
//动画时间
:root {
  --animate-duration: 0.5s !important;
  --animate-delay: 0.5s !important;
  --animate-repeat: 0.5 !important;
}
html,
body,
#app {
  height: 100%;
  overflow-x: hidden;
}
.ivu-card:hover {
  box-shadow: none !important;
  border-color: transparent !important;
}
.layout-list-pages {
  padding-top: 20px;
  text-align: right;
}
.layout-list-toolsbar {
  padding-bottom: 10px;
}
.layout-collapsed-sider {
  transition: all 0.2s ease-in-out;
}
.data-list-container {
  position: relative;
  height: 100%;
  padding: 0px 10px 0px 10px;
}
.price-color {
  color: #cc0212;
}
.status-color-n {
  //color: #cc0212;
  color: #f90;
}
.status-color-y {
  color: #09b10f;
}
.status-color-i {
  color: #2db7f5;
}
.flex-between{
   display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}

.pin-icon-load {
  animation: ani-spin 1s linear infinite;
}
@keyframes ani-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-table-header-bg {
  .ivu-table th {
    background-color: #fff;
  }
  .ivu-table:before {
    content: "";
    height: 0 !important;
  }
  .ivu-table tr:last-child td {
    border: none;
  }
}

.add-container {
  height: 100%;
  position: relative;
  .add-main {
    position: absolute;
    top: 70px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
}

.search-auto-complete-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.ivu-select-item {
    cursor: auto;
  }
  &.ivu-select-item:hover {
    background-color: transparent;
  }
}

.search-auto-complete-list {
  height: 200px;
  overflow: scroll;
  .list-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.search-auto-complete-list,
.search-auto-complete-list-title {
  .list-title {
    flex: 1;
    text-align: center;
  }
}
.search-auto-complete-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.query-tools {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 2px solid #f5f5f5;
  text-align: right;
}
.ivu-form-item {
  margin-bottom: 10px !important;
}
.ivu-form .ivu-form-item-label {
  font-size: 12px !important;
  margin-top: 2px;
}
.ivu-select-selected-value{
  font-size: 12px !important;
  margin-top: 2px;
}
.ivu-input {
   font-size: 12px !important;
}
.ivu-form-item-error-tip {
    font-size: 5px !important;
     padding-top: 0px !important;
}
#logininput .ivu-input-group-prepend   {
  background-color: #1c74d5 !important;
  border: 1px solid #0be3ff !important;
  border-radius: 15px 0px 0px 15px !important;
}
#logininput .ivu-input {
  background-color: #1c74d5 !important;
  border: 1px solid #0be3ff !important;
  border-radius: 15px 15px 15px 15px !important;
  color: #01F5FF;
}

#logininputnew .ivu-input-group-prepend   {
  background-color: #f5f9ff !important;
  border: 1px solid #f5f9ff !important;
}

#logininputnew  .ivu-input::-webkit-input-placeholder {
  color:#515A6E;
}

#logininputnew  .ivu-input {
  border-radius: 20px;
  outline:none !important;
    border:none !important;
  background-color: #f5f9ff  !important;
  border: 1px solid #f5f9ff  !important;
  color: #333;
}

#logininputnew  .ivu-input:focus{
    outline:none !important;
    border:none !important;
    background-color: #f5f9ff !important;
    box-shadow: 0 0 0 2px #f5f9ff !important;
    border: 1.3px solid #f5f9ff !important;
}

#logininputnew  .ivu-input:hover{
    outline:none !important;
    border:none !important;
    background-color: #f5f9ff !important;
    box-shadow: 0 0 0 2px #f5f9ff !important;
    border: 1.3px solid #f5f9ff !important;
}

#searchinput {
  background-color: #1d43ac !important;
  border-radius: 20px !important;
  margin-top: 15px;
}
#searchinput .ivu-input {
  border-radius: 20px 40px 0px 20px !important;
  border: 2px solid #1d43ac !important;
  height: 40px;
  padding-left: 20px;
}
#searchinput .ivu-input-search {
  border-radius: 0px 20px 20px 0px !important;
  background-color: #1d43ac !important;
  border: 2px solid #1d43ac !important;
  width:110px;
}
#vendeemodel .ivu-modal-footer {
    border-top: 1px solid #fff;
}
</style>
